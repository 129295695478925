import * as React from "react";

const IndexPage = () => {
  return (
    <>
      <title>Hello World!</title>
      <header className="m-4">
        <h1 className="text-7xl">
          <span role="img" aria-label="waving hand emoji">
            👋
          </span>{" "}
          Hello World!
        </h1>
      </header>
      <main className="m-4">
        <p>
          <span role="img" aria-label="smiling emoji">
            😊
          </span>{" "}
          Peter Holló
          <br />
          <span role="img" aria-label="house emoji">
            🏠
          </span>{" "}
          Elsterstraße 45
          <br />
          <span role="img" aria-label="cityscape emoji">
            🏙️
          </span>{" "}
          04109 Leipzig
          <br />
          <span role="img" aria-label="brezels emoji">
            🥨
          </span>{" "}
          Germany
          <br />
          <span role="img" aria-label="mailbox emoji">
            📬
          </span>{" "}
          hello(at)songsforthe(dot)dev
        </p>
      </main>
    </>
  );
};

export default IndexPage;
